import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import logo from '../assets/images/full_logo_white.png'
import {
  PracticeAreas,
  AboutUs,
  OurTeam,
  ContactForm,
  Testimonials,
} from './Home'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'
import esLocaleData from 'react-intl/locale-data/es'

import Locales from '../data/locales'
import Header from './header'
import Layout from './layout'
import { Helmet } from 'react-helmet'

addLocaleData({ ...enLocaleData, ...Locales.en })
addLocaleData({ ...esLocaleData, ...Locales.es })
addLocaleData({ ...Locales.cn })

const Hero = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  align-content: center;

  .cover {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo {
    display: block;
    position: absolute;
    width: 400px;
    left: calc(50% - 200px);
    top: calc(50% - 100px);

    @media only screen and (max-width: 800px) {
      width: 50%;
      left: calc(50% - 25%);
      top: calc(50% - 10%);
    }
  }
`

const IndexQuery = graphql`
  query {
    splash: file(relativePath: { eq: "splash.jpg" }) {
      ...ImageFragment
    }
  }
  fragment ImageFragment on File {
    childImageSharp {
      fluid(maxWidth: 1680, quality: 90, grayscale: true) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const HomePage = ({ pageContext }) => {
  return (
    <StaticQuery query={IndexQuery}>
      {data => (
        <IntlProvider
          locale={pageContext.langKey}
          messages={Locales[pageContext.langKey]}
        >
          <Layout>
            <Helmet>
              <title>
                Pérez Law, PC - Personal Injury and Workers' Compensation
                Lawyer
              </title>
            </Helmet>
            <Header />
            <Hero>
              <Img
                style={{ width: '100vw' }}
                fluid={data.splash.childImageSharp.fluid}
              />
              <div className="cover" />
              <img src={logo} className="logo" alt="logo" />
            </Hero>
            <PracticeAreas local={pageContext.langKey} />
            <AboutUs locale={pageContext.langKey} />
            <OurTeam />
            <Testimonials />
            <ContactForm />
          </Layout>
        </IntlProvider>
      )}
    </StaticQuery>
  )
}

export default HomePage
