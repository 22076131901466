import cx from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import * as styles from './contact-form.module.scss'

// import Recaptcha from 'react-google-recaptcha'

const ImageQuery = graphql`
  query {
    splash: file(absolutePath: { regex: "/contact-us/" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1680, quality: 90, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  align-content: center;
  flex-direction: column;

  .cover {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h2`
  font-size: 0.9em;
  color: #d2ae5f;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  padding-top: 1em;
  border-top: 1px solid #d2ae5f;
  width: 190px;
  position: relative;
  margin: 8em auto 2em;
`

const HeroTitle = styled.h3`
  font-size: 1.7em;
  line-height: 1.4em;
  color: #fff;
  width: 80%;
  margin: 0 auto 5em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  position: relative;
  letter-spacing: 2px;
`

const messageStyles = cx(styles.field, styles.fullWidth)
const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

export class ContactForm extends React.Component {
  handleRecaptcha = (value) => {
    this.setState({ 'g-recaptcha-response': value })
  }

  render() {
    return (
      <StaticQuery query={ImageQuery}>
        {(data) => (
          <Hero
            id="contact-form"
            style={{
              backgroundImage: `url('${data.splash.publicURL}'`,
              backgroundSize: 'cover',
            }}
          >
            <div className="cover" />
            <Title>
              <FormattedMessage id="menu.contact-us" />
            </Title>
            <HeroTitle>Call for free consultation</HeroTitle>
            <form
              className={styles.form}
              name="contact"
              method="post"
              action="https://formspree.io/mvqyrnlx"
            >
              <input
                type="hidden"
                name="_next"
                value="https://perezlawcorp.com/success"
              />
              <input type="hidden" name="_cc" value="info@perezlawcorp.com" />
              <div className={styles.field}>
                <input type="text" name="name" required />
                <label>Your Name</label>
              </div>
              <div className={styles.field}>
                <input type="email" name="email" />
                <label>E-mail</label>
              </div>
              <div className={styles.field}>
                <input type="text" name="subject" />
                <label>Subject</label>
              </div>
              <div className={styles.field}>
                <input type="phone" name="phone" required />
                <label>Phone Number</label>
              </div>
              <div className={messageStyles}>
                <textarea name="message" />
                <label>Message</label>
              </div>
              <button type="submit">Send</button>
            </form>
            <div className={styles.contactInfo}>
              <div>
                <label>California Office</label>
                <p>
                  822 North Euclid Avenue
                  <br />
                  Ontario, CA, 91762-2760
                </p>
                <span className="website">www.perezlawcorp.com</span>
              </div>
              <div>
                <label>Direct Contact</label>
                <dl>
                  <dt>Toll Free:</dt>
                  <dd>[ 877 ] 622-5888</dd>
                  <dt>Local:</dt>
                  <dd>[ 909 ] 983-2235</dd>
                  <dt>Fax:</dt>
                  <dd>[ 909 ] 983-2811</dd>
                  <dt>E-mail:</dt>
                  <dd>info@perezlawcorp.com</dd>
                </dl>
              </div>
            </div>
          </Hero>
        )}
      </StaticQuery>
    )
  }
}
