import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>SEXUAL HARASSMENT / ASSAULT</h3>
      <div className="content">
        <p>
          Sexual harassment and assault is much more common than most people
          think, especially in the workplace. While the public has only recently
          become more aware of the prevalence of these abuses thanks to the
          #MeToo movement, federal and state law has long recognized that
          victims of sexual assault need to be protected and remedies created to
          provide compensation. Both men and women can be victims of unwanted
          touching, verbal and visual harassment, assaults, and rape.
        </p>
        <p>
          Oftentimes out of fear of losing their job, victims will keep quiet
          about these abuses and sometimes even submit to unwanted sexual
          activity, especially if the aggressor is a supervisor or owner of the
          company. Employers and co-workers who abuse their positions of
          authority in order to receive sexual favors must be held accountable
          for their actions. A <em>quid pro quo</em> demand against an employee
          is a crime that no one should have to endure, especially when the
          employee feels compelled to submit to these demands in order to keep
          their job or advance in their career.
        </p>
        <p>
          A workplace environment must provide opportunities for employees to
          achieve their work objectives without having to deal with unwanted
          sexual advances or harassment. An unwanted or abusive sexual encounter
          at work can have long-lasting physical, mental, and emotional
          consequences, and it is comforting to have a legal team on your side
          that will take your hand during this difficult process and do
          everything possible to fight aggressively for your rights.
        </p>
        <p>
          Pérez Law has experience handling all types of sexual harassment and
          sexual assault cases, and we have succeeded in obtaining the highest
          level of compensation possible for our clients who have been subjected
          to these kinds of abuses.
        </p>
        <p>
          To receive a free and confidential consultation with our team of
          experienced attorneys, call Pérez Law at{' '}
          <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-hand-paper" />
      <h4>SEXUAL HARASSMENT / ASSAULT</h4>
      <p>
        Sexual harassment and assault is much more common than most people
        think, especially in the workplace. While the public has only recently
        become more aware of the prevalence of these abuses thanks to the #MeToo
        movement, federal and state law has long recognized that victims of
        sexual assault need to be protected and remedies created to provide
        compensation. Both men and women can be victims of unwanted touching,
        verbal and visual harassment, assaults, and rape.
      </p>
      <p>
        Oftentimes out of fear of losing their job, victims will keep quiet
        about these abuses and sometimes even submit to unwanted sexual
        activity ...
      </p>
    </React.Fragment>
  ),
}
