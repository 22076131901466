import React from 'react'
import styled from 'styled-components'
import * as styles from './styles.module.scss'
import { FormattedMessage } from 'react-intl'

const Title = styled.h2`
  font-size: 0.9em;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  padding-top: 1em;
  border-top: 1px solid #000;
  width: 190px;
  margin: 0 auto;
`

const HeroTitle = styled.h3`
  font-size: 1.7em;
  line-height: 1.4em;
  color: #000;
  width: 80%;
  margin: 1em auto 3em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-top: 1em;

  .white {
    color: white;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1em;
    width: 100%;
    hyphens: auto;
    border-bottom: 1px solid #000;
    padding-bottom: 1em;
  }
`

const Description = styled.div`
  color: #000;
  font-size: 13px;
  line-height: 1.8em;
  column-count: 2;
  font-weight: 300;
  width: 60%;
  margin: 0 auto;
  column-gap: 80px;

  &::first-line,
  strong {
    font-weight: 400;
  }

  hr {
    margin-top: 1.5em;
    border: 0;
    background: #000;
    width: 100%;
    height: 1px;
  }

  @media only screen and (max-width: 800px) {
    column-count: 1;
    width: 100%;
  }
`
const es = () => (
  <div
    className={styles.section}
    id="about-us"
    style={{ background: '#d6b25c' }}
  >
    <Title>
      <FormattedMessage id="menu.about-us" />
    </Title>
    <HeroTitle>
      Nosotros somos{' '}
      <span className="white">
        consejeros, estrategas, defensores, negociadores
      </span>
      , y <span className="white">abogados litigantes</span>. Nuestro equipo
      procura satisfacer las necesidades de nuestros clientes por medio de
      representación <span className="white">agresiva, ética, y honesta</span>.
    </HeroTitle>
    <Description>
      <p>
        Cada abogado y miembro de nuestro equipo está dedicado a estos valores,
        y como equipo nos encargamos de satisfacer a nuestros clientes con
        cooperación y apoyo mutuo. Nosotros siempre lo mantendremos actualizado
        en cada paso durante el proceso de su caso, y siempre estaremos
        disponibles a contestar sus preguntas.
      </p>

      <p>
        Nuestra firme convicción es que es sumamente importante lograr la
        confianza y lealtad de nuestros clientes con comunicación abierta y
        honesta, y atención personal a cada cliente. Por esta razón, la gran
        mayoría de nuestros clientes llegan a través de referencias personales.
      </p>
      <hr />
      <p>
        Para recibir una consulta gratuita con un miembro de nuestro equipo
        legal, llámenos al <strong>1-877-622-5888.</strong>
      </p>
    </Description>
  </div>
)
const en = () => (
  <div
    className={styles.section}
    id="about-us"
    style={{ background: '#d6b25c' }}
  >
    <Title>
      <FormattedMessage id="menu.about-us" />
    </Title>
    <HeroTitle>
      We are{' '}
      <span className="white">
        counselors, strategists, advocates, negotiators,
      </span>{' '}
      and <span className="white">trial attorneys</span>. Our team seeks to meet
      the needs of our clients through{' '}
      <span className="white">aggressive, ethical, and honest</span>{' '}
      representation
    </HeroTitle>
    <Description>
      Each attorney and staff member at Pérez Law is commited to these values,
      and we are all part of one firm dedicated to meeting the needs of our
      clients through teamwork and mutual support. We are dedicated to our
      clients and put them before everything and everyone else. As our client,
      we strive to deliver strategic services to help you achieve your goals. We
      are a hard-working, energetic, and skilled team of professionals that
      strive to provide direct and timely communication throughout all the
      stages of your case. We believe it is essential to again our clients'
      trust and loyalty by maintaining open and honest communication. We have a
      passion for our work, and an unshakeable dedication to our clients'
      interests. This is why the majority of our cases come to us through client
      referrals.
      <hr />
      <strong>
        To receive a free consultation from a member of our legal team, call us
        at: [877] 622-5888
      </strong>
    </Description>
  </div>
)

const cn = () => (
  <div
    className={styles.section}
    id="about-us"
    style={{ background: '#d6b25c' }}
  >
    <Title>
      <FormattedMessage id="menu.about-us" />
    </Title>
    <HeroTitle>
      我们是您的
      <span className="white">顾问、分析师、辩护律师、谈判代表</span>
      以及<span className="white">审判庭律师</span>。 我们的团队提供的陈述具有
      <span className="white">说服力、合乎道德伦理，诚实可信</span>
      ，而我们也将尽力满足客户的所有需求。
    </HeroTitle>
    <Description>
      <p>
        无论何时何地，我们都遵守最高的伦理道德标准，对待我们的客户、对方当事人、法院我们都始终诚实守信、公平公正。
      </p>
      <p>
        佩雷斯律师事务所里的每位律师和员工都全心全意为客户服务，将客户的需求放在第一位。我们努力为客户提供有效的策略，帮助客户实现他们的目标。我们的专业团队吃苦耐劳、精力充沛、经验丰富，可以在整个诉讼过程中与您进行直接且及时的沟通。
      </p>
      <p>
        我们相信只有保持坦诚交流才能获得客户的信赖。我们热爱自己的工作，始终坚持把客户的利益放在首位，这也是我们的大多数案件都是通过老客户推荐而来的原因。
      </p>
      <hr />
      <strong>
        您可以拨打877-622-5888，我们的律师团队会为您提供一次免费咨询。
      </strong>
    </Description>
  </div>
)

const Locales = { en, es, cn }

export const AboutUs = ({ locale }) => {
  return Locales[locale]()
}
