import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>ABUSO SEXUAL INFANTIL</h3>
      <div className="content">
        <p>
          Un incidente de abuso sexual infantil es un crimen detestable que
          puede tener un impacto mental y emocional eterno y negativo en un niño
          víctima. La mayoría de los crímenes contra niños son cometidos por
          personas en las que el niño confía, y la persona joven puede ser
          reacia a hablar de ello por temor a repercusiones no deseadas.
        </p>
        <p>
          Pérez Law, PC tiene mucha experiencia con casos de abuso y asalto
          sexual infantil, y podemos tomar el cargo legal de su familia y exigir
          justicia para su niño/a que ha sido abusado por una maestra, familiar,
          niñera, etc. No sólo hemos ganado millones de dólares para nuestros
          clientes que han sido víctimas de estos tipos de abusos, pero también
          les hemos tomado la mano durante en proceso legal como si fueran
          nuestra propia familia y les hemos mostrado el más alto nivel de
          atención y apoyo porque sabemos que es entre las cosas más difíciles
          que le puede pasar a la familia entera.
        </p>
        <p>
          Si su hijo o hija ha sido abusado sexualmente, es sumamente importante
          que usted consulte con un abogado con experiencia que le pueda
          asesorar y ayudarle a comenzar a tomar los pasos necesarios para que
          su niño reciba tratamiento psicológico y que pueda tomar el cargo
          legal de responsabilizar a los culpables.
        </p>
        <p>
          Para recibir una consulta gratuita y confidencial con un miembro de
          nuestro equipo legal, llámenos al <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-user-shield" />
      <h4>ABUSO SEXUAL INFANTIL</h4>
      <p>
        Un incidente de abuso sexual infantil es un crimen detestable que puede
        tener un impacto mental y emocional eterno y negativo en un niño
        víctima. La mayoría de los crímenes contra niños son cometidos por
        personas en las que el niño confía, y la persona joven puede ser reacia
        a hablar de ello por temor a repercusiones no deseadas.
      </p>
      <p>
        Pérez Law, PC tiene mucha experiencia con casos de abuso y asalto sexual
        infantil, y podemos tomar el cargo legal de su familia y exigir justicia
        para su niño/a que ha sido abusado por una maestra, familiar, niñera,
        etc. No sólo hemos ganado millones de dólares para nuestros clientes que
        han sido víctimas de estos tipos de abusos, pero también les hemos
        tomado la mano durante en proceso legal como si fueran nuestra propia
        familia y les hemos mostrado el más alto nivel de atención y apoyo
        porque sabemos que es entre las cosas más difíciles que le puede pasar a
        la familia entera.
      </p>
    </React.Fragment>
  ),
}
