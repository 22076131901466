import React from 'react'
import Panel from './Panel'
import { injectIntl } from 'react-intl'
import Locales from './Locales'

export const PracticePanel = injectIntl(props => {
  return (
    <Panel
      {...props}
      truncated={() => Locales[props.intl.locale][props.name].truncated()}
      full={() => Locales[props.intl.locale][props.name].full()}
    />
  )
})
