import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>工伤赔偿</h3>
      <div className="content">
        <p>
          佩雷斯律师事务所在法庭上为那些工作中受伤的工人代言。加利福尼亚的工伤赔偿系统非常复杂，并且难以应付，为了能少赔不赔医疗费，工伤保险公司无所不用其极。而工伤赔偿系统正是由这些保险公司设计的，他们可以使用合法的策略拒绝你的赔偿申请，拒绝或拖延你应得的治疗和利益，并且指派给你的医生为你出具的报告保护的是保险公司而不是你的利益。
        </p>
        <p>
          当你在工作的时候受伤而你的老板却否认你受伤或是保险公司拒绝你的索赔时，我们能够理解你是多么的沮丧和无助。你可以向我们的律师团队诉说你的受伤经过以及程度，无论是身体上的还是心理上的。我们经验丰富的律师团队始终在你左右，让你无须担心你的案件进展。佩雷斯律所尽其所能以确保我们的客户获得最好的治疗，同时我们也尽全力为客户争取应得的赔偿。
        </p>
        <p>
          我们的收费视您案件的解决情况而定，这意味着费用的支付将由您来决定。如果没有最终赔偿，就无需付费。我们负责处理您的案件，而您只用专心治疗尽快恢复健康。大多数与我们合作的医疗机构提供的治疗都不需要您自掏腰包，医疗费用会从结案后获得的赔偿中支出。
        </p>
        <p>
          <strong>
            您可以拨打877-622-5888，我们经验丰富的律师团队会为您提供一次免费咨询。
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-donate" />
      <h4>工伤赔偿</h4>
      <p>
        佩雷斯律师事务所在法庭上为那些工作中受伤的工人代言。加利福尼亚的工伤赔偿系统非常复杂，并且难以应付，为了能少赔不赔医疗费，工伤保险公司无所不用其极。而工伤赔偿系统正是由这些保险公司设计的，他们可以使用合法的策略拒绝你的赔偿申请，拒绝或拖延你应得的治疗和利益，并且指派给你的医生为你出具的报告保护的是保险公司而不是你的利益。
      </p>
      <p>
        当你在工作的时候受伤而你的老板却否认你受伤或是保险公司拒绝你的索赔时，我们能够理解你是多么的沮丧和无助。你可以向我们的律师团队诉说你的受伤经过以及程度，无论是身体上的还是心理上的。我们经验丰富的律师团队始终在你左右，让你无须担心你的案件进展。佩雷斯律所尽其所能以确保我们的客户获得最好的治疗，同时我们也尽全力为客户争取应得的赔偿。
      </p>
    </React.Fragment>
  ),
}
