import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>DAÑOS Y LESIONES PERSONALES</h3>
      <div className="content">
        <p>
          Pérez Law, PC es un bufete legal que representa a demandantes que han
          sufrido daños personales en California causados por accidentes de
          carro, accidentes de camión, motocicleta, y bicicleta, accidentes
          peatonales, caídas, resbalones, homicidio por negligencia, heridas
          catastróficas, ataques, acosos y asaltos sexuales, mordidas de perro,
          ataques de animal, y otros tipos de agravios personales.
        </p>
        <p>
          Tenemos una reputación de proveerles a nuestros clientes una
          representación agresiva y eficaz para lograr el mejor resultado que se
          pueda en cada caso. El enfoce de un ajustador de seguros es ahorrarle
          dinero a la compañía de seguros, y son mucho más propensos a
          aprovecharse de una persona lesionada que no tiene representación
          legal. Con nosotros en su equipo, usted estará en la mejor posición
          para pelear por sus derechos y asegurarse de que nadie se aproveche de
          usted.
        </p>
        <p>
          El equipo de Pérez Law, PC se dedica a ser atento y accesible para
          contestar cualquier pregunta que usted pueda tener acerca de su caso.
          Estamos a sus ordenes 24 horas al día, 7 días a la semana, y usted
          siempre tendrá contacto directo con un representante en nuestra
          oficina y podrá hablar directamente con los abogados cuando quiera.
        </p>
        <p>
          Nuestros honorarios son sujetos a un “pacto de quota litis,” lo cual
          significa que nuestro interés en el asunto es contingente en el
          resultado que le conseguimos.{' '}
          <strong>Si no ganamos, no cobramos.</strong> Nosotros nos encargamos
          de que su caso avance suavemente mientras que usted reciba cualquier
          tratamiento médico y/o psicológico que sea necesario para su
          recuperación. La mayoría de los proveedores médicos con quien nosotros
          trabajamos ofrecen tratamiento basado en un gravamen en su
          compensación, lo cual significa que los gastos médicos no se pagan
          hasta que se cierre su caso.
        </p>
        <p>
          Para recibir una consulta gratuita con un miembro de nuestro equipo
          legal, llámenos al <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-first-aid" />
      <h4>DAÑOS Y LESIONES PERSONALES</h4>
      <p>
        Pérez Law, PC es un bufete legal que representa a demandantes que han
        sufrido daños personales en California causados por accidentes de carro,
        accidentes de camión, motocicleta, y bicicleta, accidentes peatonales,
        caídas, resbalones, homicidio por negligencia, heridas catastróficas,
        ataques, acosos y asaltos sexuales, mordidas de perro, ataques de
        animal, y otros tipos de agravios personales.
      </p>
      <p>
        Tenemos una reputación de proveerles a nuestros clientes una
        representación agresiva y eficaz para lograr el mejor resultado que se
        pueda en cada caso. El enfoce de un ajustador de seguros es ahorrarle
        dinero a la compañía de seguros, y son mucho más propensos a
        aprovecharse de una persona lesionada que no tiene representación legal.
        Con nosotros en su equipo, usted estará en la mejor posición para pelear
        por sus derechos y asegurarse de que nadie se aproveche de usted...
      </p>
    </React.Fragment>
  ),
}
