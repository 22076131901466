import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>CHILD SEXUAL ABUSE</h3>
      <div className="content">
        <p>
          An incident of child sexual abuse is a detestable crime that can cause
          long-lasting, painful mental and emotional damage to a child victim.
          The majority of sexual crimes against children are committed by people
          who the child knows and trusts, and the child can feel reluctant to
          speak about this abuse for fear of negative repercussions.
        </p>
        <p>
          We at Pérez Law have experience with cases of child sexual abuse and
          assault, and we can take on the legal burden and fight for the justice
          your child deserves, whether he/she has been abused by a teacher,
          family member, babysitter, etc. Not only have we won millions of
          dollars for our child victims, but we have also stood side by side
          with our clients and their families to provide the support that they
          need, because we know that this is one of the most difficult
          situations that a family can ever go through.
        </p>
        <p>
          If your child has been abused sexually, it’s extremely important that
          you consult with an experienced attorney as soon as possible so that
          you can begin to take the steps necessary to get your child the help
          that he/she needs, and to ensure that important deadlines are not
          missed that could preclude you from pursuing the legal remedies
          available to you and your family.
        </p>
        <p>
          For a free and confidential consultation from our team of experienced
          attorneys, call Pérez Law at <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-user-shield" />
      <h4>CHILD SEXUAL ABUSE</h4>
      <p>
        An incident of child sexual abuse is a detestable crime that can cause
        long-lasting, painful mental and emotional damage to a child victim. The
        majority of sexual crimes against children are committed by people who
        the child knows and trusts, and the child can feel reluctant to speak
        about this abuse for fear of negative repercussions.
      </p>
      <p>
        We at Pérez Law have experience with cases of child sexual abuse and
        assault, and we can take on the legal burden and fight for the justice
        your child deserves, whether he/she has been abused by a teacher, family
        member, babysitter, etc. Not only have we won millions of dollars for
        our child ...
      </p>
    </React.Fragment>
  ),
}
