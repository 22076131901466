import React from 'react'
import styled from 'styled-components'
import * as styles from './styles.module.scss'
import { PracticePanel } from './PracticeAreas'
import { FormattedMessage } from 'react-intl'

const Title = styled.h2`
  font-size: 0.9em;
  color: #d6b35c;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  padding-top: 1em;
  border-top: 1px solid #d6b35c;
  width: 190px;
  margin: 0 auto;
`

const SubTitle = styled.h3`
  font-size: 1.7em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 5em;

  @media only screen and (max-width: 800px) {
    margin-bottom: 2em;
    border-bottom: 1px solid #d6b35c;
    padding-bottom: 1em;
    font-size: 1em;
  }
`

export const PracticeAreas = ({ locale }) => (
  <div className={styles.section} id="practice">
    <Title>
      <FormattedMessage id="menu.practice-areas" />
    </Title>
    <SubTitle>Attorneys-at-law | Abogados</SubTitle>
    <Panels locale={locale} />
  </div>
)

class Panels extends React.Component {
  constructor(props) {
    super(props)
    this.state = { openPanel: null }
  }

  onOpen = panelName => {
    this.setState({ openPanel: panelName })
  }

  onClose = () => {
    this.setState({ openPanel: null })
  }

  render() {
    const props = {
      onOpen: this.onOpen,
      onClose: this.onClose,
      openPanel: this.state.openPanel,
    }

    return (
      <div className={styles.practicePanels}>
        <PracticePanel {...props} name="personal-injury" />
        <PracticePanel {...props} name="workers-comp" />
        <PracticePanel {...props} name="sexual-harassment" />
        <PracticePanel {...props} name="childs-abuse" />
      </div>
    )
  }
}
