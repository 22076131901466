import React from 'react'
import * as css from './Panel.module.scss'
import { FormattedMessage } from 'react-intl'

export default class PracticeAreaPanel extends React.Component {
  render() {
    const { truncated, full, onOpen, onClose, openPanel, name } = this.props
    if (openPanel && openPanel !== name) {
      return null
    }
    if (openPanel && openPanel === name) {
      return (
        <div className={css.panelOpen}>
          {full()}
          <button onClick={() => onClose(name)}>
            <FormattedMessage id="app.close" defaultMessage="close" /> ->
          </button>
        </div>
      )
    }
    return (
      <div className={css.panel}>
        {truncated()}
        <button onClick={() => onOpen(name)}>
          <span>
            <FormattedMessage id="app.readMore" defaultMessage="read more" />
          </span>{' '}
          <i className="fas fa-long-arrow-alt-right" />
        </button>
      </div>
    )
  }
}
