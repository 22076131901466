import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>儿童性虐待</h3>
      <div className="content">
        <p>
          儿童性虐待是不可饶恕的犯罪行为，此类事件会给受害人带来伴随一生的心理和情感伤害。大多数针对儿童的性侵犯都是由熟人所犯，而受害的儿童却因为害怕受到负面影响而不愿意谈及所遭受的侵犯。
        </p>
        <p>
          佩雷斯律所在处理儿童性骚扰和性侵犯案件上拥有丰富的经验，无论您的孩子是否遭受过老师、家庭成员、保育员的侵害，我们都能够承担举证责任，为您的孩子争取本应获得公正待遇。我们不仅为儿童受害者赢得的数百万美元的赔偿，同时我们还与客户及他们的家庭在一起面对困难，为他们提供所需的帮助，因为我们知道这是一个家庭所能面对的最难境地。
        </p>
        <p>
          如果您的孩子遭受了性侵，最关键的一点是您要尽快向一名经验丰富的律师咨询，以便您能采取必要措施帮助您的孩子，同时确保您在有效期限内申请到法律救济。
        </p>
        <p>
          <strong>
            您可以拨打1-877-622-5888，我们经验丰富的律师团队会为您提供一次免费咨询，全程保密。
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-user-shield" />
      <h4>儿童性虐待</h4>
      <p>
        儿童性虐待是不可饶恕的犯罪行为，此类事件会给受害人带来伴随一生的心理和情感伤害。大多数针对儿童的性侵犯都是由熟人所犯，而受害的儿童却因为害怕受到负面影响而不愿意谈及所遭受的侵犯。
      </p>
      <p>
        佩雷斯律所在处理儿童性骚扰和性侵犯案件上拥有丰富的经验，无论您的孩子是否遭受过老师、家庭成员、保育员的侵害，我们都能够承担举证责任，为您的孩子争取本应获得公正待遇。我们不仅为儿童受害者赢得的数百万美元的赔偿，同时我们还与客户及他们的家庭在一起面对困难，为他们提供所需的帮助，因为我们知道这是一个家庭所能面对的最难境地。
      </p>
    </React.Fragment>
  ),
}
