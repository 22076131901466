import React from 'react'
import styled from 'styled-components'
import * as styles from './styles.module.scss'
import * as css from './team.module.scss'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { find } from 'lodash'
import { FormattedMessage } from 'react-intl'

const Title = styled.h2`
  font-size: 0.9em;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  padding-top: 1em;
  border-top: 1px solid #000;
  width: 190px;
  margin: 0 auto;
`

const HeroTitle = styled.h3`
  font-size: 1.7em;
  line-height: 1.4em;
  color: #000;
  width: 80%;
  margin: 1em auto 3em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-top: 1em;
`

const Portraits = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const Portrait = ({ name, edges, title, fullName }) => {
  const image = find(edges, e => e.node.name === name)
  return (
    <div className={css.portrait}>
      <div className={css.image}>
        <GatsbyImage fluid={image.node.childImageSharp.fluid} />
        {fullName && (
          <div className={css.portraitMeta}>
            <label className={css.name}>{fullName}</label>
            <label className={css.title}>{title}</label>
          </div>
        )}
      </div>
    </div>
  )
}

const PortraitsQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/portraits/" } }) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export const OurTeam = () => (
  <StaticQuery query={PortraitsQuery}>
    {data => (
      <div
        className={styles.section}
        id="our-team"
        style={{ background: 'white' }}
      >
        <Title>
          <FormattedMessage id="menu.our-team" />
        </Title>
        <HeroTitle>The amazing staff members</HeroTitle>
        <Portraits>
          <div className={css.ricardos}>
            <Portrait
              name="Ricardo-Perez"
              {...data.allFile}
              fullName="Ricardo Antonio Pérez"
              title="Attorney & Founder"
            />
            <Portrait
              name="Ricardo-Perez-Jr"
              {...data.allFile}
              fullName="Ricardo Agustin Pérez"
              title="Attorney-at-Law"
            />
          </div>
          <div className={css.restOfTeam}>
            <Portrait name="1" {...data.allFile} />
            <Portrait name="2" {...data.allFile} />
            <Portrait name="3" {...data.allFile} />
            <Portrait name="4" {...data.allFile} />
            <Portrait name="5" {...data.allFile} />
            <Portrait name="6" {...data.allFile} />
            <Portrait name="7" {...data.allFile} />
            <Portrait name="8" {...data.allFile} />
            <Portrait name="9" {...data.allFile} />
            <Portrait name="10" {...data.allFile} />
            <Portrait name="11" {...data.allFile} />
            <Portrait name="12" {...data.allFile} />
          </div>
        </Portraits>
      </div>
    )}
  </StaticQuery>
)
