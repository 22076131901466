import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>人身伤害</h3>
      <div className="content">
        <p>
          佩雷斯律师事务所受理的人身伤害包括轿车事故、货车事故、摩托车事故、自行车事故、步行事故、跌倒、身体攻击或侵犯、被狗或其他动物袭击引起的人身伤害，以及意外致死和灾难造成的伤害。
        </p>
        <p>
          我们向客户提供的陈述极具说服力，并尽可能为每个案件争取最好结果。保险精算师的目标就是为保险公司省下更多的钱，如果您没有法律代表，那么精算师们很可能会占您的便宜。如果有了我们，您在争取自己权益时将会处于一个更有利位置，我们可以确保没人能占您的便宜或是降低您的索赔。
        </p>
        <p>
          佩雷斯律所的律师团队细心关注每位客户，在受理您案件的过程中回答您的所有问题。我们一周七天每天24小时为您提供服务，您将始终能直接联系到办公室的工作人员，并能直接与律师沟通。
        </p>
        <p>
          我们的收费视您案件的解决情况而定，这意味着费用的支付将由您来决定。如果没有最终裁决，就无需付费。我们负责处理您的案件，而您只用专心治疗尽快恢复健康。大多数与我们合作的医疗机构提供的治疗都不需要您自掏腰包，医疗费用会从结案后获得的赔偿中支出。
        </p>
        <p>
          <strong>
            您可以拨打877-622-5888，我们经验丰富的律师团队会为您提供一次免费咨询。
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-first-aid" />
      <h4>人身伤害</h4>
      <p>
        佩雷斯律师事务所受理的人身伤害包括轿车事故、货车事故、摩托车事故、自行车事故、步行事故、跌倒、身体攻击或侵犯、被狗或其他动物袭击引起的人身伤害，以及意外致死和灾难造成的伤害。
      </p>
      <p>
        我们向客户提供的陈述极具说服力，并尽可能为每个案件争取最好结果。保险精算师的目标就是为保险公司省下更多的钱，如果您没有法律代表，那么精算师们很可能会占您的便宜。如果有了我们，您在争取自己权益时将会处于一个更有利位置，我们可以确保没人能占您的便宜或是降低您的索赔。
      </p>
    </React.Fragment>
  ),
}
