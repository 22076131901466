import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>WORKERS’ COMPENSATION</h3>
      <div className="content">
        <p>
          Pérez Law, PC is a law firm that represents workers who have been
          injured on the job. The California workers’ compensation system is
          complicated and difficult to navigate, and workers’ compensation
          insurance companies fight aggressively in order to pay as little as
          possible and provide the bare minimum of medical treatment. The
          workers’ compensation system was designed by the insurance companies,
          and they have at their disposal legally valid tactics to deny your
          case, deny or delay your medical treatment and benefits, and send you
          to doctors who will give you reports that favor the insurance
          company’s interests.
        </p>
        <p>
          We understand how frustrating it can be when you are injured on the
          job and your boss denies that your injury took place or the insurance
          company denies your claim. We compassionate legal team is dedicated to
          providing an environment in which you can be free to share with us how
          your injury occurred and the extent of your physical or psychological
          injuries. With our experienced team on your side, you won’t have to
          worry about anything during the course of your case. Pérez Law is
          dedicated to assuring that our clients receive the best treatment
          possible, and we fight to obtain the compensation to which you are
          entitled.
        </p>
        <p>
          Our attorney’s fees are contingent upon the settlement of your case,
          which means that we only get paid if you do.{' '}
          <strong>If there is no recovery, there is no fee.</strong> We take on
          the responsibility of handling your case so that you can focus on your
          medical and/or psychological treatment and recovery as quickly as
          possible.
        </p>
        <p>
          <strong>
            To receive a free consultation from one of our experienced
            attorneys, call us at 1-877-622-5888.
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-donate" />
      <h4>WORKERS’ COMPENSATION</h4>
      <p>
        Pérez Law, PC is a law firm that represents workers who have been
        injured on the job. The California workers’ compensation system is
        complicated and difficult to navigate, and workers’ compensation
        insurance companies fight aggressively in order to pay as little as
        possible and provide the bare minimum of medical treatment. The workers’
        compensation system was designed by the insurance companies, and they
        have at their disposal legally valid tactics to deny your case, deny or
        delay your medical treatment and benefits, and send you to doctors who
        will give you reports that favor the insurance company’s interests...
      </p>
    </React.Fragment>
  ),
}
