import PersonalInjury from './PersonalInjury'
import WorkersComp from './WorkersComp'
import SexualHarassment from './SexualHarassment'
import ChildAbuse from './ChildAbuse'

export default {
  'personal-injury': PersonalInjury,
  'workers-comp': WorkersComp,
  'sexual-harassment': SexualHarassment,
  'childs-abuse': ChildAbuse,
}
