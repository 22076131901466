import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>ACCIDENTES EN EL TRABAJO</h3>
      <div className="content">
        <p>
          Pérez Law, PC es un bufete legal que representa a demandantes que han
          sufrido lesiones en el trabajo. El sistema de indemnización al
          trabajador es bien complicado y difícil para navegar. Las compañías de
          seguros y sus abogados pelean agresivamente para pagar lo mínimo
          posible en cada caso. Las leyes de indemnización al trabajador en
          California son escritas por las compañías de seguros, y por esa razón
          ellos tienen en su arsenal muchas tácticas para negar su caso
          injustamente, negarle su tratamiento médico, hacerle pasar por
          retrasos en su tratamiento médico hasta el punto de desesperación, y
          mandarle a doctores que le vayan a dar reportes médicos que sean a
          favor de ellos y no de usted.
        </p>
        <p>
          Nosotros entendemos qué frustrante puede ser cuando uno se lastima en
          el trabajo y el empleador niega que sucedió la lesión o la compañía de
          seguros niega el caso. Nuestro equipo compasivo se dedica a proveerle
          un ambiente en cual usted nos puede contar la historia de su accidente
          y el alcance de sus quejas médicas. Con la experiencia de los abogados
          y personal de Pérez Law, usted no tendrá que preocuparse de nada
          durante el proceso de su caso. Nuestra oficina se dedica a asegurar
          que nuestros clientes reciban el mejor tratamiento médico posible, y
          peleamos por la máxima compensación que podemos conseguirles.
        </p>
        <p>
          Nuestros honorarios son sujetos a un “pacto de quota litis,” lo cual
          significa que nuestro interés en el asunto es contingente en el
          resultado que le conseguimos.{' '}
          <strong>Si no ganamos, no cobramos</strong>. Nosotros nos encargamos
          de que su caso avance suavemente mientras que usted reciba cualquier
          tratamiento médico y/o psicológico que sea necesario para su
          recuperación.
        </p>
        <p>
          Para recibir una consulta gratuita con un miembro de nuestro equipo
          legal, llámenos al <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-donate" />
      <h4>ACCIDENTES EN EL TRABAJO</h4>
      <p>
        Pérez Law, PC es un bufete legal que representa a demandantes que han
        sufrido lesiones en el trabajo. El sistema de indemnización al
        trabajador es bien complicado y difícil para navegar. Las compañías de
        seguros y sus abogados pelean agresivamente para pagar lo mínimo posible
        en cada caso. Las leyes de indemnización al trabajador en California son
        escritas por las compañías de seguros, y por esa razón ellos tienen en
        su arsenal muchas tácticas para negar su caso injustamente, negarle su
        tratamiento médico, hacerle pasar por retrasos en su tratamiento médico
        hasta el punto de desesperación, y mandarle a doctores que le vayan a
        dar reportes médicos que sean a favor de ellos y no de usted.
      </p>
      <p>
        Nosotros entendemos qué frustrante puede ser cuando uno se lastima en el
        trabajo y el empleador niega que sucedió la lesión o la compañía de
        seguros niega el caso ...
      </p>
    </React.Fragment>
  ),
}
