import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>性骚扰/性侵犯</h3>
      <div className="content">
        <p>
          性骚扰和性侵犯发生的情况比大多数人想象的要多，特别是在工作场所。由于#MeToo运动的出现，现在越来越多的人意识到这些侵犯行为普遍存在于各种场合中，联邦和各州法律以前就认识到，性侵犯受害者需要保护、救济和赔偿。无论是男性还是女性都同样可能遭遇不情愿的触摸、口头上和视觉上的骚扰、侵犯以及强暴。
        </p>
        <p>
          当性骚扰的施动者为权力上位者或公司老板时，这使得受害人由于害怕失去工作更倾向于选择沉默，而非反抗，有时甚至会屈从于非意愿的性行为。那些利用他们的职位权利侵犯他人的雇主和同事必须为他们的行为负责。向员工提出无法忍受的请求，对此再进行补偿也还是犯罪，哪怕雇员是为了保住工作或是加薪升职而被迫服从这些要求。
        </p>
        <p>
          在一个良好的工作环境中，雇员应该无需面对非意愿的性骚扰或性侵犯就能够实现他们的工作目标。在工作上遇到的非意愿性侵犯会对身体、心理以及情感上造成永久的伤害，如果您有一支专业的法律团队在您身边，他们会在艰难的起诉过程中紧握您的双手，倾尽全力为您争取权益。
        </p>
        <p>
          佩雷斯律所在处理各种类型的性骚扰和性侵犯案件方面拥有丰富的经验，我们已经成功地帮助我们遭受过侵犯的客户获得最大限度的赔偿。
        </p>
        <p>
          <strong>
            您可以拨打877-622-5888，我们经验丰富的律师团队会为您提供一次免费咨询，全程保密。
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-hand-paper" />
      <h4>性骚扰/性侵犯</h4>
      <p>
        性骚扰和性侵犯发生的情况比大多数人想象的要多，特别是在工作场所。由于#MeToo运动的出现，现在越来越多的人意识到这些侵犯行为普遍存在于各种场合中，联邦和各州法律以前就认识到，性侵犯受害者需要保护、救济和赔偿。无论是男性还是女性都同样可能遭遇不情愿的触摸、口头上和视觉上的骚扰、侵犯以及强暴。
      </p>
      <p>
        当性骚扰的施动者为权力上位者或公司老板时，这使得受害人由于害怕失去工作更倾向于选择沉默，而非反抗，有时甚至会屈从于非意愿的性行为。那些利用他们的职位权利侵犯他人的雇主和同事必须为他们的行为负责。向员工提出无法忍受的请求，对此再进行补偿也还是犯罪，哪怕雇员是为了保住工作或是加薪升职而被迫服从这些要求。
      </p>
    </React.Fragment>
  ),
}
