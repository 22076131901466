import React from 'react'
import styled from 'styled-components'
import * as styles from './styles.module.scss'
import Swiper from 'react-id-swiper/lib/custom'
import { FormattedMessage } from 'react-intl'
import avvo from '../../assets/images/avvo.svg'

const Title = styled.h2`
  font-size: 0.9em;
  color: #d6b35c;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  padding-top: 1em;
  border-top: 1px solid #d6b35c;
  width: 190px;
  margin: 0 auto;
`

const HeroTitle = styled.h3`
  font-size: 1.7em;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-top: 1em;
`

const StyledTestimonials = styled.div`
  padding: 2em 0 5em !important;
  position: relative;

  .swiper-container {
    position: unset !important;
  }

  .swiper-pagination {
    bottom: 2em !important;
  }

  .swiper-pagination-bullet {
    background: #c3c4c5;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    background: #d6b35c;
  }
`

const StyledTestimonial = styled.div`
  background: #d4ae55;
  padding: 2em;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px #000;
  position: relative;

  @media only screen and (max-width: 800px) {
    padding: 5px 5px 2em;

    section {
      padding: 1em;
    }
  }

  :not(.swiper-slide-active) {
    background: #7c6d40;

    h4 {
      color: #7c6d40;
    }
  }

  h3 {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0;

    &:before {
      border-top: 1px solid #000;
      content: '';
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1;
    }

    span {
      background: #000;
      padding: 1em 4em;
      font-weight: 300;
      font-size: 12px;
      color: #d4ae55;
      text-transform: uppercase;
    }
  }

  h4 {
    margin: 2em 0 0;
    text-align: center;
    color: white;
    font-size: 12px;
  }
`

const Testimonial = ({ text, name, title }) => (
  <StyledTestimonial className="swiper-slide">
    <section>{text()}</section>
    <h3>
      <span>{name}</span>
    </h3>
    <h4>{title}</h4>
  </StyledTestimonial>
)

const params = {
  initialSlide: 3,
  centeredSlides: true,
  slidesPerView: 2.5,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  spaceBetween: 50,
  breakpoints: {
    800: {
      slidesPerView: 1.25,
      spaceBetween: 20,
    },
  },
}

export const Testimonials = () => (
  <StyledTestimonials className={styles.section} id="testimonials">
    <Title>
      <FormattedMessage id="menu.testimonials" />
    </Title>
    <HeroTitle>What our clients say</HeroTitle>
    <div style={{ textAlign: 'center', margin: '5em 0' }}>
      <img src={avvo} style={{ width: '450px', margin: '0' }} alt="Avvo trusted" />
    </div>
    <Swiper {...params}>
      <Testimonial
        name="Winston L"
        text={() => (
          <section>
            <p>
              Words can't express my gratitude to Ricardo for giving me such a
              great experience in terms of consultation and representing me.
              Ricardo was very responsible with every little detail throughout
              the case and made sure I was caught up with all the information
              and dates. Needless to say, I won't be going anywhere but here.
              Thanks again!
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
      <Testimonial
        name="James L"
        text={() => (
          <section>
            <p>
              I must commend Mr. Ricardo Pérez and his staff for their
              outstanding customer service. I was in a MVA (Mar 17) and
              desperately needed someone to represent us. We had many options
              but decided the best option for us was Pérez Law. They stayed open
              late to hear our situation and calm our angst.
            </p>
            <p>
              The doctors they recommend are good and work with you.
              Unfortunately the communication between doctors, insurance
              companies, lawyers, and client (me) hit a snag on the road during
              the process. Fortunately for us, Ricardo and his team took the
              situation and went above and beyond to fix the issues to ensure
              there was constant communication. I must point out that Ricardo
              even gave me an update on my case while he was sick at home. This
              speaks volumes. He delivered the kind of results that would make
              me return if I ever need legal services again.
            </p>
            <p>
              Everyone at Perez Law is communicative and vested in your case, I
              highly recommend their services.
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
      <Testimonial
        name="Monica Elena R."
        text={() => (
          <section>
            <p>
              On February 28th, Perez Law had to be the 20th call of the day...
              I had been trying to get an attorney to take my case and the only
              response I heard all day was "sorry, we can't help you...".
            </p>
            <p>
              Ricardo Antonio Perez (I won't ever forget that name) changed
              that... although he knew he could not take my case, it didn't stop
              him from giving me the time of the day and some much needed
              guidance to point me in the right direction. His advice was
              genuine and empathetic; not the norm in this industry. It is
              evident that Mr. Perez has a true passion for what he does and on
              that day he reminded me that there is still some good hearted
              people in this world who really do give all that they are to help
              others; thank you for that.{' '}
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
      <Testimonial
        name="Dorene N."
        text={() => (
          <section>
            <p>
              I would like to say how pleased I am with Perez Law Corporation,
              especially Mr. Ricardo.
              <br />
              He was the reason that I have won a case that I thought I would
              never win.
              <br />
              He keeps on trying without giving up, doing teamwork with his son,
              one heart, one hand, and I was able to win my case.
              <br />
              Even though my English is not that good, he did not care, he
              helped me till the last breath. <br />
              He did not care about the money or abou time, he just wanted to
              win the case and he eventually did.
              <br />
              Please don't hesitate contacting with Mr. Ricardo.
              <br />
              強烈推薦想要找工傷或車禍律師他們專業的經驗毫不猶豫幫了我百分百的專業對英文不好他們也會請中文翻譯所以別再猶豫不決找他們就對了
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
      <Testimonial
        name="Jairo Z"
        text={() => (
          <section>
            <p>
              Mr. Richardo Perez saw me the same day I called. I was in some
              serious need of encouragement after a physical altercation at work
              and when I kept getting mixed advice from friends and family I
              knew I needed to talk to a professional.
            </p>
            <p>
              As soon as I walked into his office I knew he was the real deal.
              Immediately I was greeted by his receptionist with a warm smile
              and when I met with Mr. Perez he inspired a sense of comfort and
              confidence that I desperately needed. Altho I did not open a case
              with him he was kind enough to share his knowledge and point me in
              the right direction. If my situation at work worsens I will
              certainly be coming back to him.
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
      <Testimonial
        name="Valerie F."
        text={() => (
          <section>
            <p>
              I can see why there are such great reviews about Pérez Law, I had
              a great experience here! I got the help I needed quick, I must say
              everything was done in a timely manner. Ricardo and Axel are very
              welcoming and helpful with any questions I had. I was confident
              that if I had a question Axel would get back by phone or email
              quickly! I will refer them to anyone in the same situation and I
              highly recommend using them.
            </p>
          </section>
        )}
        title="Personal Injury Client"
      />
    </Swiper>
  </StyledTestimonials>
)
