import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>ACOSO Y ASALTO SEXUAL</h3>
      <div className="content">
        <p>
          Aunque muchos no lo sepan, acosos y asaltos sexuales suceden
          comúnmente, especialmente en el trabajo, lo cual ha provocado el
          movimiento #YoTambién igual como legislación federal y estatal
          diseñado a proteger a víctimas y crear remedios de compensación
          monetario. Acoso y asalto sexual puede afectar a hombres igual que a
          mujeres, y ambos puedes ser víctimas de tocamientos indeseados, acosos
          verbales o visuales, asaltos, y violaciones. Por temor de perder su
          trabajo, muchos suelen no reportar estos abusos y hasta a veces se
          entregan a actividades sexuales indeseados, particularmente cuando el
          agresor es un supervisor o dueño de la empresa.
        </p>
        <p>
          Los empleadores y compañeros de trabajo que abusan de sus posiciones
          de poder y autoridad para recibir favores sexuales deben rendir
          cuentas por los delitos de abuso sexual en el trabajo. Una demanda
          <em>quid pro quo</em> contra un trabajador es un crimen que nadie
          debería tener que soportar, especialmente cuando está convencido de
          que la única forma de avanzar en una carrera es someterse al abuso
          sexual.
        </p>
        <p>
          Un entorno de trabajo debería proporcionar una oportunidad para que
          las personas trabajen para lograr sus objetivos sin tener que lidiar
          con avances sexuales o abuso. Un encuentro sexual no deseado o abusivo
          en el trabajo puede tener efectos físicos, mentales, y emocionales con
          cuales la víctima tenga que lidiar por muchos años. Es reconfortante
          tener un equipo de abogados que le tomen la mano durante este proceso
          difícil y que esté dedicado a pelear por sus derechos.
        </p>
        <p>
          Pérez Law, PC tiene experiencia manejando todo tipo de casos de acoso
          y asalto sexual y hemos logrado conseguirle la mayor compensación a
          nuestros clientes que han sido víctimas de abusos sexuales.
        </p>
        <p>
          Para recibir una consulta gratuita y confidencial con un miembro de
          nuestro equipo legal, llámenos al <strong>1-877-622-5888.</strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-hand-paper" />
      <h4>ACOSO Y ASALTO SEXUAL</h4>
      <p>
        Aunque muchos no lo sepan, acosos y asaltos sexuales suceden comúnmente,
        especialmente en el trabajo, lo cual ha provocado el movimiento
        #YoTambién igual como legislación federal y estatal diseñado a proteger
        a víctimas y crear remedios de compensación monetario. Acoso y asalto
        sexual puede afectar a hombres igual que a mujeres, y ambos puedes ser
        víctimas de tocamientos indeseados, acosos verbales o visuales, asaltos,
        y violaciones. Por temor de perder su trabajo, muchos suelen no reportar
        estos abusos y hasta a veces se entregan a actividades sexuales
        indeseados, particularmente cuando el agresor es un supervisor o dueño
        de la empresa.
      </p>
      <p>
        Los empleadores y compañeros de trabajo que abusan de sus posiciones de
        poder y autoridad para recibir favores sexuales deben rendir cuentas por
        los delitos de abuso sexual en el trabajo ...
      </p>
    </React.Fragment>
  ),
}
