import React from 'react'

export default {
  full: () => (
    <React.Fragment>
      <h3>Personal Injury</h3>
      <div className="content">
        <p>
          Pérez Law, PC is a law firm that represents plaintiffs who have been
          suffered personal injuries in California caused by car accidents,
          truck accidents, motorcycle accidents, bicycle accidents, pedestrian
          accidents, slips and falls, physical or sexual assaults, dog bites,
          and animal attacks, including wrongful deaths and catastrophic
          injuries.
        </p>
        <p>
          We have a reputation for providing our clients with aggressive and
          effective representation in order to achieve the best possible result
          in each case. The goal of every insurance adjuster is to save the
          insurance company as much money as possible, and adjusters are far
          more likely to try to take advantage of you if you don’t have legal
          representation. With us on your side, you are in a better position to
          be able to fight for your rights and ensure that no one takes
          advantage of you or undervalues your claim.
        </p>
        <p>
          Our team at Pérez Law is dedicated to being attentive and available to
          our clients in order to answer any questions that may arise during the
          course of your case. We are at your service 24 hours a day, 7 days a
          week, and you will always have direct communication with someone from
          our office and the ability to speak to one of our attorneys.
        </p>
        <p>
          Our attorney’s fees are contingent upon the settlement of your case,
          which means that we only get paid if you do. If there is no recovery,
          there is no fee. We take on the responsibility of handling your case
          so that you can focus on your medical and/or psychological treatment
          and recovery as quickly as possible. The majority of medical providers
          that we work with provide treatment on a lien basis, which means that
          you are not responsible for out-of-pocket medical expenses and the
          doctors are paid from the settlement proceeds at the conclusion of
          your case.
        </p>
        <p>
          <strong>
            To receive a free consultation from one of our experienced
            attorneys, call us at 1-877-622-5888.
          </strong>
        </p>
      </div>
    </React.Fragment>
  ),
  truncated: () => (
    <React.Fragment>
      <i className="fas fa-first-aid" />
      <h4>Personal Injury</h4>
      <p>
        Pérez Law, PC is a law firm that represents plaintiffs who have been
        suffered personal injuries in California caused by car accidents, truck
        accidents, motorcycle accidents, bicycle accidents, pedestrian
        accidents, slips and falls, physical or sexual assaults, dog bites, and
        animal attacks, including wrongful deaths and catastrophic injuries.
      </p>
      <p>
        We have a reputation for providing our clients with aggressive and
        effective representation in order to achieve the best possible result in
        each case. The goal of every insurance adjuster is to save the insurance
        company as much money as possible, and adjusters are far more likely to
        try to take advantage ...
      </p>
    </React.Fragment>
  ),
}
